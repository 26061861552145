import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { saveData } from '../../../services/onboarding.service';
import { showError, showSuccess } from '../../../services/shared/alert.service';
import { useAuthStore, useonboardingStore } from '../../../store';
import { ButtonSmall } from '../../units/Button';
import { InputsRadio, InputsWithTooltip } from '../../units/Inputs';
import {
  changeShopifyName,
  mapFee,
  validateInputs,
} from '../../../services/shared/utils.service';
import { motion } from 'framer-motion';
import { customerRouteName, customerName } from '../../../data/InfoSource';
import {
  childVariants,
  fadeInAndUp,
  fadeInAndUpNoDelay,
  growBtn,
} from '../../../services/shared/animation.service';

export const DeliveryTimesForm = ({ ecommerce }) => {
  let history = useHistory();
  const addData = useonboardingStore((state) => state.addData);
  const auth = useAuthStore((state) => state.authData);
  const store = useonboardingStore(
    (state) => state.onboardingData.store.locations[0]
  );

  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [enableRadios, setEnableRadios] = useState(false);
  const [enableAutoType, setEnableAutoType] = useState(true);
  const [fixedBackup, setFixedBackup] = useState('');
  const [adjustedBackup, setAdjustedBackup] = useState('');
  const [body, setBody] = useState({
    createAt: store?.createAt || 'panel',
    typeOfFee: store?.typeOfFee || 'dynamic',
    fee: '',
    city: store?.city,
    province: store?.province,
    postalCode: store?.postalCode,
    street: store?.street,
    externalNumber: store?.externalNumber,
    // internalNumber: store?.internalNumber,
    userId: store?.userId,
  });
  const [radioValue, setRadioValue] = useState('+');
  const radios = [
    { name: 'Descuento', value: '-' },
    { name: 'Recargo', value: '+' },
  ];

  useEffect(() => {
    if (store?.fee) {
      if (store.typeOfFee === 'adjusted') {
        const values = mapFee(store.fee);
        setRadioValue(values.operator);
        setBody({ ...body, fee: values.number });
        setAdjustedBackup(store.fee);
      } else {
        setBody({ ...body, fee: store.fee });
        setFixedBackup(store.fee);
      }
    }
  }, []);

  const handleChange = (e) => {
    let value = e.target.value;
    value = value === '' ? '' : parseFloat(value).toString();
    setBody({ ...body, [e.target.id]: value });
  };

  const handleRadioChange = (e) => {
    if (e.target.id === 'auto') {
      setEnableRadios(true);
    }
    if (e.target.id === 'panel') {
      setEnableRadios(false);
      setEnableAutoType(true);
    }
    if (
      e.target.id === 'created' ||
      e.target.id === 'packed' ||
      e.target.id === 'paid'
    ) {
      setEnableAutoType(false);
    }
    if (e.target.checked) {
      setBody({ ...body, createAt: e.target.value });
    }
  };

  const handleRadioChangeForFee = (e) => {
    if (e.target.checked) {
      if (e.target.id === 'dynamic') {
        setBody({ ...body, typeOfFee: e.target.value, fee: '' });
      }
      if (e.target.id === 'fixed') {
        setBody({ ...body, typeOfFee: e.target.value, fee: fixedBackup || '' });
      }
      if (e.target.id === 'adjusted') {
        let prevValue = mapFee(adjustedBackup);
        setBody({ ...body, typeOfFee: e.target.value, fee: prevValue.number });
        setRadioValue(prevValue.operator);
      }
      if (e.target.id === 'dynamic' || e.target.id === 'fixed') {
        setRadioValue('+');
      }
    }
  };

  const setDeliveryPromise = async (e) => {
    e.preventDefault();
    if (validateInputs(body, setFormErrors)) {
      setIsLoading(true);
      let toSend = {
        createAt: body.createAt,
        typeOfFee: body.typeOfFee,
        fee: Number(`${radioValue}${body.fee}`) || '',
      };
      let toSave = {
        locations: [{ ...body, fee: Number(`${radioValue}${body.fee}`) || '' }],
      };
      if (auth.user.insidePanel) {
        toSend = { ...toSend, atPanel: true };
        toSave = {
          locations: [
            { ...body, fee: Number(`${radioValue}${body.fee}`), atPanel: true },
          ],
        };
      }
      try {
        const result = await saveData(toSend, auth.user.storeId);
        if (result && result.success) {
          addData({
            store: {
              ...toSave,
            },
          });
          showSuccess(result.message);
          if (auth.user.insidePanel) {
            history.push(`/${customerRouteName}/${ecommerce}/panel/rate`);
          } else {
            history.push(`/${customerRouteName}/${ecommerce}/finish`);
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showError(error);
      }
    } else {
      return;
    }
  };

  return (
    <form onSubmit={setDeliveryPromise}>
      <motion.div
        className="delivery-type mb-3"
        variants={auth.user.insidePanel ? fadeInAndUpNoDelay : fadeInAndUp}
        initial="hidden"
        animate="visible"
      >
        <h2 className="step-title fw-bold">
          Creación de órdenes en {customerName}
        </h2>
        <h3 className="step-subtitle">
          Defina cómo quiere que se generen las órdenes en {customerName}.
        </h3>

        <motion.div variants={childVariants}>
          <InputsRadio
            id="panel"
            name="createAt"
            value="panel"
            labelTxt={`Quiero crear manualmente mis órdenes ingresando a mi panel de ${customerName}.`}
            onChangeFn={handleRadioChange}
            checked={body.createAt === 'panel'}
          />

          <InputsRadio
            id="auto"
            name="createAt"
            value="auto"
            labelTxt="Quiero que se creen automáticamente a partir de alguna de las siguientes acciones:"
            onChangeFn={handleRadioChange}
            checked={body.createAt === 'auto'}
            disabled={enableAutoType ? false : true}
          />
        </motion.div>

        <motion.div variants={childVariants} id="subtype-wrapper">
          <InputsRadio
            id="created"
            name="createAt"
            value="created"
            labelTxt="Cuando la orden es creada."
            onChangeFn={handleRadioChange}
            checked={body.createAt === 'created'}
            disabled={enableRadios ? false : true}
          />

          <InputsRadio
            id="paid"
            name="createAt"
            value="paid"
            labelTxt="Cuando la orden es pagada."
            onChangeFn={handleRadioChange}
            checked={body.createAt === 'paid'}
            disabled={enableRadios ? false : true}
          />

          <InputsRadio
            id="packed"
            name="createAt"
            value="packed"
            labelTxt="Cuando la orden es empaquetada."
            onChangeFn={handleRadioChange}
            checked={body.createAt === 'packed'}
            disabled={enableRadios ? false : true}
          />
        </motion.div>
      </motion.div>
      <motion.div
        className="delivery-type"
        variants={fadeInAndUp}
        initial="hidden"
        animate="visible"
      >
        <h2 className="step-title fw-bold">Tarifa</h2>
        <h3 className="step-subtitle">
          Defina el tipo de Tarifa que desea aplicar
        </h3>

        <motion.div variants={childVariants}>
          <InputsRadio
            id="dynamic"
            name="typeOfFee"
            value="dynamic"
            labelTxt={`Tarifa dinámica de ${customerName}.`}
            onChangeFn={handleRadioChangeForFee}
            checked={body.typeOfFee === 'dynamic'}
          />
          <motion.div variants={childVariants} id="rate-wrapper">
            <InputsRadio
              id="fixed"
              name="typeOfFee"
              value="fixed"
              labelTxt="Tarifa fija."
              onChangeFn={handleRadioChangeForFee}
              checked={body.typeOfFee === 'fixed'}
            />
            {body.typeOfFee === 'fixed' && (
              <div className="feeFixed-phrase">
                <span className="form-check-label">Costo de envío de $</span>
                <InputsWithTooltip
                  value={body.fee}
                  id="fee"
                  type="number"
                  onChangeFn={handleChange}
                  min={0}
                />
                {formErrors.fee && (
                  <span className="invalid-msg">{formErrors.fee}</span>
                )}
              </div>
            )}
          </motion.div>
        </motion.div>
        <motion.div variants={childVariants} id="rate-wrapper">
          <InputsRadio
            id="adjusted"
            name="typeOfFee"
            value="adjusted"
            labelTxt="Tarifa dinámica ajustada."
            onChangeFn={handleRadioChangeForFee}
            checked={body.typeOfFee === 'adjusted'}
          />
          {body.typeOfFee === 'adjusted' && (
            <div className="fee-phrase">
              <span className="form-check-label">Quiero aplicar</span>
              <InputsWithTooltip
                value={body.fee}
                id="fee"
                type="number"
                onChangeFn={handleChange}
                min={0}
              />
              {formErrors.fee && (
                <span className="invalid-msg">{formErrors.fee}</span>
              )}
              <span className="form-check-label">% de</span>

              <div className={`radios-wrapper ${ecommerce}`}>
                {radios.map((radio, idx) => (
                  <div key={idx}>
                    <input
                      id={`radio-${idx}`}
                      type="radio"
                      name="radio"
                      value={radio.value}
                      checked={radio.value === radioValue}
                      onChange={(e) => setRadioValue(e.currentTarget.value)}
                      className={`btn-check`}
                    />
                    <label
                      className={`btn btn-outline-custom`}
                      htmlFor={`radio-${idx}`}
                    >
                      {radio.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        </motion.div>
      </motion.div>
      {!auth.user.insidePanel ? (
        <div className="bottomPage doubleBtn">
          <motion.div
            variants={growBtn}
            initial="hidden"
            animate="visible"
            className="btn btn-link"
          >
            <Link
              to={`/${customerRouteName}/${changeShopifyName(
                ecommerce
              )}/location-list`}
              className="step-subtitle fw-bold"
            >
              Atrás
            </Link>
          </motion.div>

          <motion.div variants={growBtn} initial="hidden" animate="visible">
            <ButtonSmall
              ecommerce={ecommerce}
              type="submit"
              btnTxt={
                body.createAt &&
                body.createAt !== 'auto' &&
                (((body.typeOfFee === 'adjusted' ||
                  body.typeOfFee === 'fixed') &&
                  body.fee) ||
                  (body.typeOfFee === 'dynamic' && !body.fee))
                  ? 'Guardar'
                  : 'Completa todos los datos para continuar'
              }
              showSpinner={isLoading}
              isDisabled={
                body.createAt &&
                body.createAt !== 'auto' &&
                (((body.typeOfFee === 'adjusted' ||
                  body.typeOfFee === 'fixed') &&
                  body.fee) ||
                  (body.typeOfFee === 'dynamic' && !body.fee))
                  ? false
                  : true
              }
            />
          </motion.div>
        </div>
      ) : (
        <div className="bottomPage">
          <motion.div variants={growBtn} initial="hidden" animate="visible">
            <ButtonSmall
              ecommerce={ecommerce}
              type="submit"
              btnTxt={
                body.createAt &&
                body.createAt !== 'auto' &&
                (((body.typeOfFee === 'adjusted' ||
                  body.typeOfFee === 'fixed') &&
                  body.fee) ||
                  (body.typeOfFee === 'dynamic' && !body.fee))
                  ? 'Guardar'
                  : 'Completa todos los datos para continuar'
              }
              showSpinner={isLoading}
              isDisabled={
                body.createAt &&
                body.createAt !== 'auto' &&
                (((body.typeOfFee === 'adjusted' ||
                  body.typeOfFee === 'fixed') &&
                  body.fee) ||
                  (body.typeOfFee === 'dynamic' && !body.fee))
                  ? false
                  : true
              }
            />
          </motion.div>
        </div>
      )}
    </form>
  );
};
